<template>
  <header class=" text-center">
    <nav class="navbar navbar-expand-lg fixed-top">
      <div class="container">
        <div class="nav-flex">
          <div class="brand-name">
            <router-link to="/">
              <img src="../assets/logo-2.png" />
            </router-link>
          </div>

          <div class="nav-list">
            <div class="menu">
              <div @click="openNav()">
                <div>
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                  <div class="bar3"></div>
                </div>
              </div>
            </div>

            <div class="lists">
              <router-link to="/home">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'Home' }"
                  style="cursor: pointer"
                >Home</span>
              </router-link>
              <router-link to="/accommodation">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'Accommodation' }"
                  style="cursor: pointer"
                >Accommodation</span>
              </router-link>
              <router-link to="/about-us">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'AboutUs' }"
                  style="cursor: pointer"
                >About Us</span>
              </router-link>
              <router-link to="/gallery">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'Gallery' }"
                  style="cursor: pointer"
                >Gallery</span>
              </router-link>
              <router-link to="/contact-us">
                <span
                  class="mr-4 list-one"
                  :class="{ active: $route.name == 'ContactUs' }"
                >Contact Us</span>
              </router-link>
              <router-link to="/contact-us">
                <span
                  class=""
                  :class="{ active: $route.name == 'ContactUs' }"
                ></span>
              </router-link>
              <el-popover
                placement="bottom"
                width="300"
                trigger="click"
              >
                <section>
                  <div class="theCheckAvailabilitySection">
                    <div>
                      <div style="text-align: left">
                        <div class="availabilityForm">
                          <div class="row">
                            <div class="col-12 mt-3">
                              <el-date-picker
                                v-model="value1"
                                type="date"
                                placeholder="Check-In"
                                style="width: 100%;"
                                size="medium"
                              >
                              </el-date-picker>
                            </div>
                            <div class="col-12 mt-3">
                              <el-date-picker
                                v-model="value1"
                                type="date"
                                placeholder="Check-Out"
                                style="width: 100%;"
                                size="medium"
                              >
                              </el-date-picker>
                            </div>
                          </div>

                          <div class="d-flex">
                            <div class="mt-3">
                              <el-input-number
                                placeholder="Adults"
                                v-model="num"
                                controls-position="right"
                                @change="handleChange"
                                style="width: 100%"
                                size="medium"
                                :min="1"
                                :max="100"
                              ></el-input-number>
                            </div>
                            <div class="mt-3">
                              <el-input-number
                                placeholder="Children"
                                v-model="num"
                                controls-position="right"
                                @change="handleChange"
                                style="width: 100%"
                                size="medium"
                                :min="0"
                                :max="100"
                              ></el-input-number>
                            </div>
                          </div>

                          <div class="mt-3">
                            <el-button
                              class="checkBtn"
                              size="medium"
                              style="border-radius: 5px"
                              @click="$router.push({path: 'accommodation'})"
                            >Check Availability <span class="el-icon-d-arrow-right"></span></el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <el-button
                  slot="reference"
                  style="background-color: #513F03; color: white;"
                >Check Availability <i class="el-icon-d-arrow-right"></i></el-button>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="">
      <el-dialog
        title="Outer Dialog"
        :visible.sync="outerVisible"
        center
      >
        <div
          slot="footer"
          class="dialog-footer"
        ></div>
      </el-dialog>
    </div>

    <el-drawer
      title="I am the title"
      :visible.sync="sidebarDrawer"
      direction="ltr"
      size="70%"
      :with-header="false"
    >
      <div class="sidebar">
        <ul>
          <li @click="closeNav()">
            <router-link to="/">
              <span
                class="mr-4 list-one"
                style="cursor: pointer"
              >Home</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/accommodation">
              <span
                class="mr-4 list-one"
                style="cursor: pointer"
              >Accommodation</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/about-us">
              <span
                class="mr-4 list-one"
                style="cursor: pointer"
              >About Us</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/gallery">
              <span class="mr-4">Gallery</span>
            </router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/contact-us">
              <span class="mr-4">Contact Us</span>
            </router-link>
          </li>
          <!-- <li @click="closeNav()">
            <router-link to="/our-people">
              <span class="mr-4">Our People</span>
            </router-link>
          </li> -->
        </ul>
      </div>
    </el-drawer>
  </header>
</template>

<script>
// import SidebarComponent from './sidebar-component.vue';
export default {
  // components: {SidebarComponent},
  data() {
    return {
      drawer: false,
      sidebarDrawer: false,
      outerVisible: false,
      innerVisible: false,
      value1: "",
      num: 0,
    };
  },

  methods: {
    openNav() {
      this.sidebarDrawer = true;
    },

    closeNav() {
      this.sidebarDrawer = false;
    },
    handleChange(value) {
      console.log(value);
    },
  },
};
</script>

<style scoped>
nav {
  background-color: #e5dea5;
}
nav a {
  color: black;
  text-decoration: none;
}
a .active {
  border-bottom: 1px solid #0e763c;
  color: #0e763c;
  font-weight: 600 !important;
}

a:hover span {
  border-bottom: 1px solid #0e763c;
  transition: 0.5s;
}

.side-link {
  color: var(--el-app-primary);
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.nav-flex .brand-name {
}

.nav-flex .nav-list {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-flex span {
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 400;
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex span:hover {
  color: #0e763c;
  transition: 0.5s;
}

.brand-name img {
  width: 100px;
  padding: 0px;
  margin: 0px;
}

.bookBtn {
  border: 1px solid #fbf2e1;
  border-radius: 0px;
  background-color: transparent;
  color: #fbf2e1;
  transition: 0.5s;
}

.bookBtn:hover {
  color: #0e763c;
  transition: 0.5s;
}

.menu {
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 2px;
  background-color: #0e763c;
  margin: 5px 0;
  border-radius: 3px;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.sidebar {
  background-color: #e5dea5;
  height: 100%;
  padding: 50px 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.sidebar ul li {
  border-bottom: 1px solid #684613;
  padding-top: 30px;
}

.sidebar ul li a {
  color: black;
}

.sidebar ul li a:hover {
  color: #0e763c;
}

.checkBtn {
  background-color: #0e763c;
  border: 2px solid #0e763c;
  border-radius: 5px;
  color: #fbf2e1;
  border-radius: 0px;
  text-transform: uppercase;
  width: 100%;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .menu {
    display: block;
  }

  .nav-flex .nav-list .lists {
    display: none;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .menu {
    display: block;
  }

  .nav-flex .nav-list .lists {
    display: none;
  }
}
</style>

<style>
el-drawer {
  background-color: red;
}
</style>
<template>
  <footer class="text-center pt-5">
    <div class="container">
      <div class="d-flex align-items-center justify-content-center">
        <div style="color: white; display: flex; font-size: 1.0em;">
          <div class="mr-3">
            <i class="fab fa-facebook"></i>
          </div>
          <div class="mr-3">
            <i class="fab fa-instagram"></i>
          </div>
          <div>
            <i class="fab fa-twitter"></i>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <ul style="color: grey; display: flex;">
          <li
            class="mr-3"
            @click="$router.push({ path: '/about-us' })"
          ><small>About Us</small></li>
          <li
            class="mr-3"
            @click="$router.push({ path: '/accommodation' })"
          ><small>Accommodation</small></li>
          <li
            class="mr-3"
            @click="$router.push({ path: '/gallery' })"
          ><small>Gallery</small></li>
          <li
            class="mr-3"
            @click="$router.push({ path: '/contact-us' })"
          ><small>Contact Us</small></li>
        </ul>
      </div>
      <small>copyright &copy; 2022 Trekker's Tavern Cottages | All Rights Reserved</small>

      <section>
        <a
          href="https://wa.me/+256 704616041/?text=Hello, Trekkers Tavern Cottages"
          class="float"
          target="_blank"
        >
          <!--<i class="fa fa-whatsapp my-float"></i>-->
          <i class="fab fa-whatsapp my-float"></i>
        </a>
      </section>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeNames: ["1"],
    };
  },

  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
footer {
  background-color: #513f03;
  color: grey;
  padding-top: 50px;
  padding-bottom: 50px;
}

a {
  color: gray;
}
footer ul {
  list-style: none;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  text-align: left;
}

footer ul li {
  margin-bottom: 15px;
  cursor: pointer;
}
.nav-flex {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.nav-flex > div:first-child,
.nav-flex > div:last-child {
  width: 100%;
}

.nav-flex .nav-list-one {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
}

.nav-flex .nav-list-two {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-flex div {
  font-size: 0.9em;
  font-weight: 400;
}

.brand-name {
  margin-left: 50px;
  margin-right: 50px;
}
.col-title {
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 50px;
}

.showOnDesktop {
  display: block;
}

.showOnMobile {
  display: none;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.my-float {
  margin-top: 16px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .nav-flex {
    display: block;
  }
  .brand-name {
    display: none;
  }

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}
</style>

<style>
footer .el-collapse-item__header {
  background-color: #513f03;
  border-bottom: 1px solid #ebeef5;
  color: #fef9f6;
  text-align: left;
}
footer .el-collapse-item {
  background-color: #513f03;
  color: #eeeeee;
  text-align: left;
}
footer .el-collapse-item__content {
  border: none;
  background-color: #513f03;
  color: #eeeeee;
  text-align: left;
}
footer .el-collapse-item__wrap {
  will-change: height;
  background-color: #513f03;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
}
</style>